import { combineReducers } from "redux";
import commonReducer from "../api/commonReducer";
import profileReducer from "../api/profile/reducer";

const rootReducer = combineReducers({
  commonReducer: commonReducer,
  profileReducer: profileReducer,
});

export default rootReducer;
