import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import errorGif from "../../assets/imgs/errorGif.gif";

const ErrorScreen = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        background: "#f2f3f4",
        overflow: "hidden",
      }}
    >
      <Box
        elevation={3}
        style={{
          padding: "20px",
          textAlign: "center",
          position: "relative",
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box style={{ position: "absolute", bottom: "200px" }}>
          <img
            src={errorGif}
            style={{ width: "70px", height: "70px", borderRadius: "50%" }}
          />
          <Typography
            variant="h4"
            gutterBottom
            style={{ color: "rgb(129, 129, 129)" }}
          >
            Oops, something went wrong!
          </Typography>
          <Typography variant="body1" style={{ color: "rgb(129, 129, 129)" }}>
            We apologize for the inconvenience.
          </Typography>
        </Box>
        <Box style={{ position: "absolute", bottom: "100px" }}>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: "#dd7973" }}
            onClick={() => window.location.reload()}
          >
            Reload
          </Button>
        </Box>
      </Box>
    </div>
  );
};
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log("error", error);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorScreen />;
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
