import { put, takeLatest, all } from "redux-saga/effects";
import { GET_PROFILE_DETAILS, SAGA_PROFILE_DETAILS } from "./enum";
import api from "../";

function* getProfileDetails() {
  try {
    const response = yield api.get("/profile-details");
    localStorage.setItem("cid", response?.data?.userDetails?.cid)
    yield put({
      type: GET_PROFILE_DETAILS,
      payload: response?.data?.userDetails,
    });
  } catch (err) {
    throw err;
  }
}

export default function* root() {
  yield all([takeLatest(SAGA_PROFILE_DETAILS, getProfileDetails)]);
}
