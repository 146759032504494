import axios from "axios";
import {  HAS_BLOCKED,ANY_PROJECT } from "./profile/enum";
import { store } from "../redux/store";

const base_url = process.env.REACT_APP_BACKEND_BASE_URL;

class Api {
  login(token) {
    localStorage.setItem("sso_token", token);
    window.location.href = "/app";
  }
  logout() {
    localStorage.clear();
    window.location.href = "/app/login";
  }
  handleSpecificApi(type,data){
    if(type === "/t2v/project/fetch"){
      store.dispatch({type:ANY_PROJECT,"payload":data?.data?.data?.length > 0});
    }
  }
  async get(url, isToken = true) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(`${base_url}${url}`, {
          headers: {
            Authorization: isToken ? "Bearer " + localStorage.getItem("sso_token") : null,
            RequestTime: new Date(),
            cid: localStorage.getItem("cid")
          },
        });
        this.handleSpecificApi(url,response.data);
        resolve(response.data);
      } catch (err) {
        if (err?.response?.status === 401) {
          this.logout();
        } else if (err?.response?.status === 402 && err?.response?.data?.error?.toLowerCase() === "not enough credits.") {
          store.dispatch({type: HAS_BLOCKED,payload: true});
        }
        reject(err?.response?.data ?? err);
      }
    });
  }
  async post(url, data, isToken = true) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(`${base_url}${url}`, data, {
          headers: {
            Authorization: isToken ? "Bearer " + localStorage.getItem("sso_token") : null,
            RequestTime: new Date(),
            cid: localStorage.getItem("cid")
          },
        });
        resolve(response.data);
      } catch (err) {
        if (err?.response?.status === 401) {
          this.logout();
        } else if (err?.response?.status === 402 && err?.response?.data?.error?.toLowerCase() === "not enough credits.") {
          store.dispatch({type: HAS_BLOCKED,payload: true});
        }
        reject(err?.response?.data ?? err);
      }
    });
  }
  async update(url) {
    return new Promise((resolve, reject) => {
      try {
      } catch (err) {}
    });
  }
  async delete(url) {
    return new Promise((resolve, reject) => {
      try {
      } catch (err) {}
    });
  }
}

const api = new Api();
export default api;
