import { GET_PROFILE_DETAILS, ANY_PROJECT } from "./enum";

const INITIAL_STATE = {
  profile: null,
  hasBlocked: false,
  isFirstTimeUser: false,
  anyProject:true
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PROFILE_DETAILS:
      return {
        ...state,
        profile: action.payload,
        hasBlocked:
          action.payload.active_plan !== "ENTERPRISE" ? 
          (action.payload.plan_status.includes("EXPIRED") ||
          action.payload.available_credits <= 0 ||
          new Date() > new Date(action.payload.plan_expire_at)) : false,
        isFirstTimeUser: action.payload.plan_status.includes("FIRST_TIME_USER"),
      };
    case ANY_PROJECT:
      return {...state,anyProject:action.payload}
    default:
      return state;
  }
};

export default reducer;
