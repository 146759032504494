import { Box, CircularProgress } from "@mui/material";

const LoaderCompo = () => (
  <Box
    style={{
      width: "100vw",
      height: "100vh",
      backgroundColor: "#404452",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <CircularProgress style={{ color: "#0267FF" }} />
  </Box>
);

export default LoaderCompo;