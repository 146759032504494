import React, { Suspense,lazy,useState,useEffect } from "react";
import { BrowserRouter as Router,Routes,Route,Navigate } from "react-router-dom";
import LoaderCompo from "./containers/Common/Loader/Loader";
import { useSelector } from "react-redux";

const LandingPage = lazy(() => import("./containers/Landing"));
const GenerateVideoPage = lazy(() => import("./containers/GenerateVideo"));
const CreateProjectPage = lazy(() => import("./containers/Project/CreateProject"));
const CreateVideoWelcomePage = lazy(() => import("./containers/CreateVideo"));
const RequestedVideosPage = lazy(() => import("./containers/Videos"));
const ShareVideoPage = lazy(() => import("./containers/ShareVideoPage"));
const LoginPage = lazy(() => import("./containers/Auth/Login"));
const RegisterPage = lazy(() => import("./containers/Auth/Register"));
const ForgotPasswordPage = lazy(() => import("./containers/Auth/ForgotPassword"));
const PaymentSuccess = lazy(() => import("./containers/Payment/PaymentSuccess"));
const PaymentFailed = lazy(() => import("./containers/Payment/PaymentFailed"));
const BillingDetails = lazy(() => import("./containers/Payment/BillingDetails"));
const UsagePage = lazy(()=> import("./containers/Usage"))
const BlockPage = lazy(() => import("./containers/Profile/Block"));

const RouterContainer = () => {
  const [isLoggedin, setIsLoggedin] = useState(false);
  const hasBlocked = useSelector((state) => state.profileReducer.hasBlocked);
  const anyProject = useSelector((state) => state.profileReducer.anyProject);
  useEffect(() => {
    let sso_token = localStorage.getItem("sso_token");
    if (sso_token) {
      setIsLoggedin(true);
    } else {
      setIsLoggedin(false);
    }
  }, []);
  const redirect = (type) => {
    switch (type) {
      case "EDITOR":
        if(hasBlocked){
          return <Navigate to="/app/billing-details" />;
        }else if(!anyProject){
          return <Navigate to="/app/project" />;
        }else{
          return <GenerateVideoPage />;
        }
      default:
        break;
    }
  }
  return (
    <Router>
      <Suspense fallback={<LoaderCompo />}>
        <Routes>
          <Route path="/app" element={<LandingPage />} />
          <Route path="/app/login" element={isLoggedin ? <Navigate to="/app" /> : <LoginPage setIsLoggedin={setIsLoggedin} /> } />
          <Route path="/app/register" element={isLoggedin ? <Navigate to="/app" /> : <RegisterPage />}/>
          <Route path="/app/forgot-password" element={isLoggedin ? <Navigate to="/app" /> : <ForgotPasswordPage />} />
          <Route path="/app/create-project" element={<CreateProjectPage />} />
          <Route path="/app/create-edit-video" element={<CreateVideoWelcomePage />}/>
          <Route path="/app/t2v/share/:videoUrl" element={<ShareVideoPage />} />
          <Route path="/app/project/:pid" element={<RequestedVideosPage />} />
          <Route path="/app/editor" element={redirect("EDITOR")}/>
          <Route path="/app/editor/:id" element={redirect("EDITOR")}/>
          <Route path="/app/billing-details" element={<BillingDetails />}/>
          <Route path="/app/usage" element={<UsagePage />}/>
          <Route path="/app/payment-success" element={<PaymentSuccess />}/>
          <Route path="/app/payment-failed" element={<PaymentFailed />}/>
          <Route path="/app/blocked" element={<BlockPage />}/>
          <Route path="*" element={ isLoggedin ? <Navigate to="/app" /> : <Navigate to="/app/login" />}/>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default RouterContainer;
